.AboutMeContainer {
    background-image: url("Brady\ Photo\ no1.PNG");
    background-attachment: fixed;
    background-position: center;
    padding: 40rem;
    justify-content: center;
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.AboutMeBox {
    border: .2rem solid black;
    top: 10%;
    border-radius: .9rem;
    padding: 6rem;
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column; 
    background-color: lemonchiffon; 
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem; 
    height: 40rem; 
    width: 40rem;
    vertical-align: middle;
    position: absolute;
    opacity: 0.98;
}

.AboutMeButton {
    background-color: darkblue;
    border: none;
    font-weight: bold;
    color: rgb(255, 255, 255);
    padding: 1rem 1.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: .7rem 0;
    cursor: pointer;
  }
  
.AboutMeButton:hover {
    background-color: hotpink;
  }

.AboutMeBoxText {
    justify-content: left;
    padding: .2rem;
}

.AboutMeImage {
    width: 30%;
    height: auto;
    border-radius: 2%;
    border: .3rem solid black;
}

.JobsGrid {
    display: flex;
    position: absolute;
    bottom: 1rem;
    justify-content: space-between;
    padding: 1rem;
    margin: 0;
    width: 50rem;
}


.jobsContainer {
    border: .3rem solid black;
    display: flex;
    height: 14rem;
    width: 13rem;
    padding: 1rem;
    background-color: white;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    border-radius: 5%;
}

.jobsContainer :hover {
    cursor: pointer;
}

.jobsContainer h2 {
    color: rgb(0, 0, 0); 
    font-size: .2rem;   
}

.jobsContainer h3 {
    font-size: 1rem;
    vertical-align: text-top;
    color:rgb(0, 0, 0);
}

.jobsContainer p {
    font-size: .77rem;
}

.jobsLogo {
    max-width: 100%;
    height: auto;
    border-radius: 5%;
    
}

.header {
    background-color: white;
    justify-content: right;
    display: flex;
}

.header h4 {
    color: white;
    z-index: 1;
    box-shadow: inset 0 0 0 0 white;   
    transition: color .8s ease-in-out, box-shadow .8s ease-in-out;
    padding: .4rem;
  }
  
  .header h4:hover {
  box-shadow: inset 100rem 0 0 0 white;
  color: black;
  cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .AboutMeContainer {
        background-image: url("Brady\ Photo\ no1.PNG");
        background-position: relative;
        justify-content: center;
        padding: 28%;
        width: 100%;
        height: 160vh;
    }
    
    .AboutMeBox {
        border: .2rem solid black;
        top: 10%;
        border-radius: .9rem;
        padding: 3%;
        background-color: lemonchiffon; 
        font-family: 'Open Sans', sans-serif;
        font-size: 1.3vh; 
        left: 3vh;
        height: 80vh; 
        width: 35vh;
        vertical-align: middle;
        position: -50vh;
        opacity: 0.98;
    }
    
    .AboutMeButton {
        background-color: darkblue;
        border: none;
        font-weight: bold;
        color: rgb(255, 255, 255);
        padding: 1% 1.5%;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1.5vh;
        margin: .7rem 0;
        cursor: pointer;
      }
      
    .AboutMeButton:hover {
        background-color: hotpink;
      }
    
    .AboutMeBoxText {
        justify-content: left;
        padding: 1%;
    }
    
    .AboutMeImage {
        width: 55%;
        height: 35%;
        border-radius: 2%;
        border: .3rem solid black;
    }
    
    .JobsGrid {
        position: absolute;
        bottom: 1.5vh;
        justify-content: space-between;
        padding: 14%;
        width: 42vh;
        right: 18vh;
    }
    
    
    .jobsContainer {
        border: .3rem solid black;
        display: flex;
        height: 22vh;
        width: 17vh;
        padding: 1%;
        background-color: white;
        align-items: center;
        justify-content: top;
        flex-direction: column;
        border-radius: 5%;
        
    }
    
    .jobsContainer :hover {
        cursor: pointer;
    }
    
    .jobsContainer h2 {
        color: rgb(0, 0, 0); 
        font-size: .3vh;   
    }
    
    .jobsContainer h3 {
        font-size: 1vh;
        vertical-align: text-top;
        color:rgb(0, 0, 0);
    }
    
    .jobsContainer p {
        font-size: 1vh;
    }
    
    .jobsLogo {
        max-width: 100%;
        height: auto;
        border-radius: 5%;
        
    }
    
    .header {
        background-color: white;
        justify-content: right;
        display: flex;
    }
    
    .header h4 {
        color: white;
        z-index: 1;
        box-shadow: inset 0 0 0 0 white;   
        transition: color .8s ease-in-out, box-shadow .8s ease-in-out;
        padding: .4rem;
      }
      
      .header h4:hover {
      box-shadow: inset 100rem 0 0 0 white;
      color: black;
      cursor: pointer;
      }
  }