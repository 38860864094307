.header {
  background-color: black;
  justify-content: right;
  display: flex;
  
  
}

.header h4 {
  color: white;
  z-index: 1;
  box-shadow: inset 0 0 0 0 white;   
  transition: color .8s ease-in-out, box-shadow .8s ease-in-out;
  
}

.header h4:hover {
box-shadow: inset 100rem 0 0 0 white;
color: black;
cursor: pointer;
}

.landingPageContainer {
  background-image: url("HawaiiVolcano Cropped.jpg");
  background-size: cover;
  padding: 40rem;
  justify-content: center;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  
}


.landingPageContainer h1 {
  font-size: 2rem;
  color: white;
  width: 50rem;
  justify-content: center;
  display: flex;
  bottom: 25rem;
  position: relative;
}


@media only screen and (max-width: 600px){
  .header {
    background-color: black;
    justify-content: right;
    display: flex;
    
    
  }
  
  .header h4 {
    color: white;
    z-index: 1;
    box-shadow: inset 0 0 0 0 white;   
    transition: color .8s ease-in-out, box-shadow .8s ease-in-out;
    
  }
  
  .header h4:hover {
  box-shadow: inset 100vh 0 0 0 white;
  color: black;
  cursor: pointer;
  }
  
  .landingPageContainer {
    background-image: url("HawaiiVolcano Cropped.jpg");
    background-position: -100vh;
    padding: 10%;
    width: 100%;
    height: 160vh;
    margin: right;
    position: relative;
  }
  
  
  .landingPageContainer h1 {
    font-size: 3vh;
    color: white;
    width: 15vh;
    height: 10vh;
    justify-content: center;
    top: 10vh;
    right: 4vh;
    position: relative;
  }
}